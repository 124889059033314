import React, { PureComponent } from 'react'
import Loadable from 'react-loadable';

import Layout from '../../components/Layouts';
import { graphql } from 'gatsby';
import '../dataviz.css';
import './unicorn.css';

export const frontmatter = {
    title: `The Global Unicorn Club`,
    written: "2020-02-01",
    updated: "2020-02-01",
    layoutType: 'post',
    contentType: "dataviz",
    path: "/global-unicorn/",
    category: "STARTUP, VISUALISATION",
    image: './poster.png',
    description: "Exploration of the Global Unicorn Club"
  }

  const LoadablePlotlyComponent = props => {      
      const Component =    Loadable({
        // loader: () => import('./CustomPlot'),
        loader: () => import('./GlobalUnicorns'),
        loading() {
            return <div>Loading the chart....</div>
        }
    })
    return <Component {...props} />
  }


export default class GlobalUnicornsIndex extends PureComponent {
    render() {        
        const { data, location } = this.props;
        
        return (
            <Layout data={data} location={location}>
            <div>
                <LoadablePlotlyComponent {...this.props} ></LoadablePlotlyComponent>
            </div>
            </Layout>
        )
    }
}

export const pageQuery = graphql`
query {
    atmosphere: file(
        relativePath: {regex: "/katnoria_plot0.png/"}) {
            childImageSharp {
                fluid(maxWidth: 1200, quality: 100) {
                    ...GatsbyImageSharpFluid
                    presentationWidth
                }
            }
        }    
    yoy: file(
        relativePath: {regex: "/katnoria_plot1.png/"}) {
            childImageSharp {
                fluid(maxWidth: 1200, quality: 100) {
                    ...GatsbyImageSharpFluid
                    presentationWidth
                }
            }
        }    
        allFile(filter: {relativePath: {regex: "/global-unicorns/images/"}}) {
            edges {
              node {
                name
                relativePath
                childImageSharp {              
                fluid(maxWidth: 1200, quality: 100) {
                    ...GatsbyImageSharpFluid
                    presentationWidth
                  }          
                }
              }
            }
          }        
    }
`