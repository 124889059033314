import React from 'react'
import Link from 'gatsby-link'
import SiteNav from '../SiteNav'
import SiteLinks from '../SiteLinks'
import logo from '../../static/images/k_icon.svg'
import KatnoriaLogo from './mylogo'
import "./Header.css"

class HeaderBar extends React.Component {
  state = {
    isMobileActive: 'navbar-menu'
  }

  handleBurgerClick = () => {
    let isMobileActive = this.state.isMobileActive
    isMobileActive =
      isMobileActive === 'navbar-menu' ? 'navbar-menu is-active' : 'navbar-menu'
    this.setState({ isMobileActive })
  }

  render() {
    const isHome = this.props.location.pathname === `/`
    const { isMobileActive } = this.state
    // console.log(isMobileActive)
    return (
      <nav className="navbar" role="navigation" aria-label="main navigation">
        <div className="navbar-brand">
          <div className="navbar-item">
            <a className="navbar-item" href="/">
              <KatnoriaLogo textcolor="#f2f2f2" text="Katnoria" />
              {/* <img src={logo} alt="Katnoria" width="112" height="28" /> */}
            </a>
          </div>
          {/* <div className="navbar-burger burger">
            <div></div>
            <div></div>
            <div></div>
          </div> */}
          <a
            role="button"
            className="navbar-burger"
            data-target="navMenu"
            aria-label="menu"
            aria-expanded="false"
            onClick={this.handleBurgerClick}
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>

        <div id="navMenu" className={isMobileActive}>
          <div className="navbar-start"></div>
          <div className="navbar-end">
            <div className="navbar-item">
              <a className="header-item" href="/">
                POSTS
              </a>
              {/* <a className="navbar-item" href="/all">ALL ⭐</a> */}
              <a className="header-item" href="/viz">
                VISUALIZATIONS
              </a>
              <a className="header-item" href="/all">
                INDEX
              </a>
              <a href="https://kb.katnoria.com" 
                className="header-item">
                SHORTS
              </a>
              {/* <a className="navbar-item" href="/misc">Misc</a> */}
              {/* <a className="navbar-item" href="/notebooks">Notebooks</a> */}
              <a className="header-item" href="/about">
                ABOUT
              </a>
            </div>
          </div>
        </div>
      </nav>
    )
  }
}

export default HeaderBar
