import React from "react"
import katnoria from './katnoria.svg'

class KatnoriaLogo extends React.Component {
    render() {
        return(
            // <svg className="umbrella" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" aria-labelledby="title">
            // <title id="title">Umbrella Icon</title>
            //     <path d="M27 14h5c0-1.105-1.119-2-2.5-2s-2.5 0.895-2.5 2v0zM27 14c0-1.105-1.119-2-2.5-2s-2.5 0.895-2.5 2c0-1.105-1.119-2-2.5-2s-2.5 0.895-2.5 2v0 14c0 1.112-0.895 2-2 2-1.112 0-2-0.896-2-2.001v-1.494c0-0.291 0.224-0.505 0.5-0.505 0.268 0 0.5 0.226 0.5 0.505v1.505c0 0.547 0.444 0.991 1 0.991 0.552 0 1-0.451 1-0.991v-14.009c0-1.105-1.119-2-2.5-2s-2.5 0.895-2.5 2c0-1.105-1.119-2-2.5-2s-2.5 0.895-2.5 2c0-1.105-1.119-2-2.5-2s-2.5 0.895-2.5 2c0-5.415 6.671-9.825 15-9.995v-1.506c0-0.283 0.224-0.499 0.5-0.499 0.268 0 0.5 0.224 0.5 0.499v1.506c8.329 0.17 15 4.58 15 9.995h-5z"/>
            //   </svg> 
            //   <svg className="umbrella" xmlns="http://www.w3.org/2000/svg" width="210" height="300" viewBox="0 0 210 297">
            //   <text font-weight="400" font-size="50.8" font-family="sans-serif" letter-spacing="0" word-spacing="0" fill="#f2f2f2" stroke-width=".265">
            //   <tspan x="-.756" y="138.25">Katnoria</tspan>
            //   </text>                            
            //   </svg>                         
            <svg xmlns="http://www.w3.org/2000/svg" width="120" height="40" viewBox="0 0 68 5">
                <text x="0" y="293.542" fontWeight="400" fontSize="50.8" fontFamily="sans-serif" letterSpacing="0" wordSpacing="0" fill={this.props.textcolor} strokeWidth=".265" transform="translate(0 -286.417)">
                <tspan x="0" y="294" fontSize="16">{this.props.text}</tspan>
                </text>
            </svg>            
            // <img src={katnoria} />
        )
    }
}

KatnoriaLogo.defaultProps = {
    textcolor: "#f2f2f2",
    text: "Katnoria"    
}
 
export default KatnoriaLogo